import { render, staticRenderFns } from "./MailTable.vue?vue&type=template&id=ce40b4ac"
import script from "./MailTable.vue?vue&type=script&lang=js"
export * from "./MailTable.vue?vue&type=script&lang=js"
import style0 from "./MailTable.vue?vue&type=style&index=0&id=ce40b4ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports